import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/default'
import { Container } from 'components/common/styles'
import styled from '@emotion/styled'
import { Document } from '@contentful/rich-text-types'
import { createBody } from '../utils/contentful'
import richSnippet from '../components/common/rich-snippet'
import { createBlogSchema } from '../utils/richSnippets'

type Props = {
  data: {
    allContentfulBlogPost: {
      edges: [
        {
          node: {
            heroImage: {
              description
              fixed: {
                src: string
                srcSet: string
              }
              socials: {
                src: string
              }
            }
            slug: string
            title: string
            publishDate: string
            rawDate: string
            author: {
              name: string
            }
            id: string
            content: {
              json: Document
            }
            description: {
              childMarkdownRemark: {
                rawMarkdownBody: string
              }
            }
            tags: string[]
          }
        }
      ]
    }
  }
};

const AdaptivePostContainer = styled(Container)`
  width: 80%;
  
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
const MainImage = styled.img`
  width: 100%;
  border-radius: 60px;
`

const Meta = styled.div`
  padding-left: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  
  display: flex;
  flex-direction: row;
  
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  
  > div:not(:last-of-type):after {
    content: "";
    display: inline-block;
    border-right: 1px solid #666;
    height: 15px;
    
    margin: 0 15px;
  }
`
const Date = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Title = styled.h1`
  font-size: 34px;
  line-height: 1.5;
  font-weight: bold;
  color: #000;
  
  margin-bottom: 25px;
`
const Content = styled.div`
  h2 {
    font-size: 24px;
    line-height: 1.5;
    color: #000;
    font-weight: bold;
    margin-bottom: 1em;
  }
  
  h3 {
    font-size: 20px;
    line-height: 1.5;
    color: #000;
    font-weight: bold;
    margin-bottom: 1em;
  }
  
  b {
    font-weight: bold;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: .5em;
  }
  
  p + h2 {
    margin-top: 30px;
  }
  
  p + h3 {
    margin-top: 15px;
  }
  
  blockquote {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
    margin-top: 40px;
    margin-bottom: 40px;
    
    font-size: 16px;
    line-height: 1.5;
    font-style: italic;
    &:before {
      content: "“";
      font-size: 180px;
      line-height: 0.57;
      color: #e5e4e4;
      font-weight: bold;
      margin-right: 25px;
      overflow: visible;
      height: 40px;
      font-style: normal;
    }
  }
  
  a {
    color: ${props => props.theme.colors.green100};
    font-weight: 500;
  }
  
  ul {
    margin-left: 2em;
    list-style: disc;
    li {
      &:not(:last-of-type) {
        margin-bottom: 0.5em;
      }
    }
    margin-bottom: 1em;
  }
  
  ol {
    margin-left: 2em;
    list-style: decimal;
    li {
      &:not(:last-of-type) {
        margin-bottom: 0.5em;
      }
    }
    margin-bottom: 1em;
  }
`

const BlogPost: React.FC<Props> = props => {
  const item = props.data.allContentfulBlogPost.edges[0].node
  React.useEffect(() => {
    window.analytics.page('Blog', item.title)
  }, [])
  return (
    <Layout
      title={`${item.title} | Blog | ComCard`}
      description={item.description.childMarkdownRemark.rawMarkdownBody}
      ogImage={item.heroImage.socials.src}
      helmetChildren={richSnippet(
        createBlogSchema(
          `https://comcard.us/blog/${props.data.allContentfulBlogPost.edges[0].node.slug}`,
          item.title,
          item.description.childMarkdownRemark.rawMarkdownBody,
          item.heroImage.socials.src,
          item.rawDate,
        ),
      )}
    >
      <AdaptivePostContainer>
        <MainImage
          src={item.heroImage.fixed.src}
          srcSet={item.heroImage.fixed.srcSet}
          alt={item.heroImage.description}
        />

        <Meta>
          <Date>{item.publishDate}</Date>
          <Author>by {item.author.name}</Author>
        </Meta>
        <Title>{item.title}</Title>
        <Content>
          {createBody(item.content?.json)}
        </Content>
      </AdaptivePostContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
    query BlogPost($slug: String!){
        allContentfulBlogPost(filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    heroImage {
                        description
                        fixed(height: 800, width: 1500, toFormat: JPG, quality: 70) {
                            src
                            srcSet
                        }
                        socials: fixed(height: 630, width: 1200, toFormat: JPG, quality: 70) {
                            src
                        }
                    }
                    slug
                    title
                    publishDate(formatString: "MMMM DD, YYYY")
                    rawDate: publishDate
                    author {
                        name
                    }
                    id
                    description {
                        childMarkdownRemark {
                            rawMarkdownBody
                        }
                    }
                    content {
                        json
                    }
                    tags
                }
            }
        }
    }
`

export default BlogPost
