import React from 'react'
import classnames from 'classnames'

const createText = (contentChunk) => {
  if (contentChunk.marks === undefined || contentChunk.marks.length === 0) {
    return contentChunk.value
  }
  return (
    <span
      key={contentChunk.value}
      className={classnames(
        contentChunk.marks.map((mark) => `text-${mark.type}`),
      )}
    >
      {contentChunk.value}
    </span>
  )
}

const createHyperlink = (hyperlinkChunk) => (
  <a rel="noopener noreferrer" target="_blank" href={hyperlinkChunk.data.uri}>
    {hyperlinkChunk.content.map((contentChunk) => {
      if (contentChunk.nodeType === 'text') return createText(contentChunk)
      return undefined
    })}
  </a>
)

const createHeader = (chunk) => {
  const header = chunk.content.map((contentChunk) => {
    if (contentChunk.nodeType === 'text') return createText(contentChunk)
    if (contentChunk.nodeType === 'hyperlink') {
      return createHyperlink(contentChunk)
    }
    return undefined
  })
  if (chunk.nodeType === 'heading-2') return <h2>{header}</h2>
  if (chunk.nodeType === 'heading-3') return <h3>{header}</h3>
  if (chunk.nodeType === 'heading-4') return <h4>{header}</h4>
  if (chunk.nodeType === 'heading-5') return <h5>{header}</h5>
  if (chunk.nodeType === 'heading-6') return <h6>{header}</h6>
  return <h1>{header}</h1>
}

const createParagraph = (chunk, includeParagraphTags = true) => {
  const paragraph = chunk.content.map((contentChunk) => {
    if (contentChunk.nodeType === 'text') return createText(contentChunk)
    if (contentChunk.nodeType === 'hyperlink') {
      return createHyperlink(contentChunk)
    }
    return undefined
  })

  const key = chunk.content[0] && chunk.content[0].value
  return includeParagraphTags ? <p key={key}>{paragraph}</p> : paragraph
}

const createList = (chunk) =>
  chunk.content.map((listItemChunk) => {
    if (listItemChunk.nodeType === 'list-item') {
      return (
        <li>
          {listItemChunk.content.map((contentChunk) => {
            if (contentChunk.nodeType === 'paragraph') {
              return createParagraph(
                contentChunk,
                listItemChunk.content.filter(
                  (content) => content.nodeType === 'paragraph',
                ).length !== listItemChunk.content.length,
              )
            }
            if (contentChunk.nodeType.startsWith('heading')) {
              return createHeader(contentChunk)
            }
            if (contentChunk.nodeType === 'unordered-list') {
              return <ul>{createList(contentChunk)}</ul>
            }
            if (contentChunk.nodeType === 'ordered-list') {
              return <ol>{createList(contentChunk)}</ol>
            }
            return undefined
          })}
        </li>
      )
    }
    return undefined
  })

const createBlockquote = (chunk) => (
  <blockquote>
    {chunk.content.map((contentChunk) => {
      if (contentChunk.nodeType === 'paragraph') {
        return createParagraph(contentChunk, false)
      }
      return undefined
    })}
  </blockquote>
)

export const createBody = (node, includeParagraphTags = true) =>
  node.content.map((chunk) => {
    if (chunk.nodeType === 'paragraph') {
      return createParagraph(chunk, includeParagraphTags)
    }
    if (chunk.nodeType.startsWith('heading')) return createHeader(chunk)
    if (chunk.nodeType === 'embedded-asset-block') {
      return null
    }
    if (chunk.nodeType === 'unordered-list') {
      return <ul>{createList(chunk)}</ul>
    }
    if (chunk.nodeType === 'ordered-list') return <ol>{createList(chunk)}</ol>
    if (chunk.nodeType === 'blockquote') return createBlockquote(chunk)
    return undefined
  })

export const createTextBody = (node): string =>
  node.content.map((chunk) => {
    if (chunk.nodeType === 'paragraph') {
      return chunk.content.map((contentChunk) => contentChunk.value)
    }
    return undefined
  }).join('\n')
